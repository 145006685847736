import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Heading from '../components/Heading'
import backgroundImage from '../../static/img/home-background-2.png'

export const RecruitPageTemaplte = ({ content, contentComponent }) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section recruit-pagee">
      <div className="container">
        <div className="content" style={{ display: 'flex' }}>
          <PostContent content={content} />
          <figure className="image is-hidden-mobile">
            <img src={backgroundImage} alt="background"></img>
          </figure>
        </div>
        <figure
          className="image is-hidden-tablet"
          style={{ margin: 'auto', marginTop: '-100%', zIndex: '-10' }}
        >
          <img src={backgroundImage} alt="background"></img>
        </figure>
      </div>
    </section>
  )
}

RecruitPageTemaplte.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

const RecruitPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet titleTemplate="Recruit | %s" />
      <Heading heading="Recruit" />
      <RecruitPageTemaplte content={post.html} contentComponent={HTMLContent} />
    </Layout>
  )
}

RecruitPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default RecruitPage

export const pageQuery = graphql`
  query RecruitPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
    }
  }
`
